import React from "react"
import styles from "./Navbar.module.scss"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import NavLinks from "./NavLinks"
import useShowOnScroll from "../../hooks/useShowOnScroll"
import Logo from "../../images/logo.svg"
import PropTypes from 'prop-types';

const Navbar = ({ toggleNav, page }) => {
  const isScrolled = useShowOnScroll()

  return (
    <nav
      className={`${styles.navbar} ${isScrolled ? styles.sticky : ""} ${
        page === "homepage" ? styles.homepage : ""
      }`}
    >
      <div className={`${styles.menu} container`}>
        <Link className={styles.link} to="/">
          <img className={styles.logo} src={Logo} alt="Logo Kovoart" />
        </Link>
        <NavLinks isScrolled={isScrolled} />
        <button
          type="button"
          className={styles.hamburgerBtn}
          onClick={toggleNav}
          aria-label="Otevřít menu"
        >
          <FaBars className={styles.hamburger} />
        </button>
      </div>
    </nav>
  )
}

export default Navbar

Navbar.propTypes = {
  toggleNav: PropTypes.func,
  page: PropTypes.string,
}

