import React from "react"
import styles from "./Footer.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import ContactBox from "../contact/ContactBox"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Fade from "react-reveal/Fade"
import links from "../../constants/footer"
import Img from "gatsby-image"

const getContacts = graphql`
  query {
    allContentfulKontakty {
      edges {
        node {
          name
          adress
          telefon
          mail
        }
      }
    }
    file(relativePath: { eq: "kvalita.png" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getContacts)
  const contacts = data.allContentfulKontakty.edges[0].node
  const img = data.file.childImageSharp.fixed;

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.info}>
          <div className={styles.contact}>
            <Fade bottom duration={700}>
              <h3>Kontakt</h3>
            </Fade>
            <a href={`tel:${contacts.telefon}`}>
              <ContactBox footer contact={contacts.telefon} icon="phone" />
            </a>
            <a href={`mailto:${contacts.mail}`}>
              <ContactBox footer contact={contacts.mail} icon="mail" />
            </a>
            <AnchorLink to="/kontakt#map" title="Mapa">
              <ContactBox footer contact={contacts.adress} icon="adress" />
            </AnchorLink>
          </div>
          <div>
            <Fade bottom duration={700}>
              <h3>Odkazy</h3>
            </Fade>
            {links.map((link, i) => {
              return (
                <Fade key={i} bottom duration={700}>
                  <div className={styles.linkBox}>
                    <div className={styles.linkName}>{link.name}</div>
                    <a
                      className={styles.link}
                      href={link.link}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {link.text}
                    </a>
                  </div>
                </Fade>
              )
            })}
          </div>
          <div>
            <Fade bottom duration={700}>
              <h3>Certifikáty</h3>
            </Fade>
            <Fade bottom duration={700}>
              <Img
                fixed={img}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
              />
            </Fade>
          </div>
        </div>
        <div className={styles.copy}>
          <div>© 2020 Kovoart</div>
          <div>
            Webové stránky:{" "}
            <a className={styles.link} href="https://www.linkedin.com/in/martin-hyttych/" target="_blank" rel="noopener noreferrer">
              Martin Hyttych
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
