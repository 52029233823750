import React from "react"
import styles from "./Button.module.scss"
import { Link } from "gatsby"
import PropTypes from 'prop-types';

const Button = ({ text, type, link, children, className, disabled }) => {
  const style =
    type === "sidebar" ? styles.sidebar : type === "main" ? styles.main : ""

  const btn = (
    <button
      disabled={disabled}
      type={link ? "button" : "submit"}
      className={`${styles.button} ${style}`}
    >
      {text}
      {children}
    </button>
  )

  if (link) {
    return (
      <Link className={className && styles[className]} to={link}>
        {btn}
      </Link>
    )
  }

  return btn
}

export default Button

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}