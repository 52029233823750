export const debounce = (func, delay) => {
  let inDebounce
  return (...args) => {
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export function shorten(str, maxLen, separator = " ") {
  if (str.length <= maxLen) return str
  return str.substr(0, str.lastIndexOf(separator, maxLen))
}

export const insertMap = () => {
  return {
    __html: `
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3642.733426549136!2d15.971251866458276!3d49.789797538380775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dbc6f8919536d%3A0xbe5311a03a2db7ad!2sVojt%C4%9Bchov%2046%2C%20539%2001%20Vojt%C4%9Bchov!5e0!3m2!1scs!2scz!4v1606247592647!5m2!1scs!2scz" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`,
  }
}

export const calcViewPortHeight = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--height", `${vh}px`)
}
