import React from "react"
import styles from "./Sidebar.module.scss"
import NavLinks from "./NavLinks"
import { FaTimes } from "react-icons/fa"
import PropTypes from 'prop-types';

const Sidebar = ({ isOpen, toggleNav }) => {
  return (
    <aside className={`${styles.sidebar} ${isOpen ? styles.active : ""}`}>
      <button
        className={styles.closeBtn}
        onClick={toggleNav}
        type="button"
        aria-label="Zavřít menu"
      >
        <FaTimes className={styles.close} />
      </button>
      <NavLinks />
    </aside>
  )
}

export default Sidebar

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
  toggleNav: PropTypes.func
}