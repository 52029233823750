export default [
  {
    name: "Vinotéka Pod kovárnou",
    link: "https://www.podkovarnou.cz/",
    text: "podkovarnou.cz",
  },
  {
    name: "Pohony posuvných bran Toušek",
    link: "https://tousek.com",
    text: "tousek.com",
  },
  {
    name: "Okrasné kameny",
    link: "http://www.okrasnekameny.eu",
    text: "okrasnekameny.eu",
  },
]