import React, { useState } from "react"
import styles from "./Navlinks.module.scss"
import links from "../../constants/links"
import { Link } from "gatsby"
import Dropdown from "./Dropdown"
import { MdArrowDropDown } from "react-icons/md"
import PropTypes from 'prop-types';

const NavLinks = ({ place, isScrolled }) => {
  const [dropdown, setDropdown] = useState(false)

  const setDropdownHandler = () => {
    setDropdown(dropdown => !dropdown)
  }

  let style = place === "footer" ? styles.footer : ""

  return (
    <ul className={`${styles.links} ${style}`}>
      {links.map((link, index) => {
        return (
          <li
            key={index}
            onMouseEnter={index === 0 ? setDropdownHandler : undefined}
            onMouseLeave={index === 0 ? setDropdownHandler : undefined}
            className={`${styles.item} ${style}`}
          >
            {dropdown && index === 0 && place !== "footer" && (
              <Dropdown isScrolled={isScrolled} />
            )}
            <Link
              partiallyActive={true}
              activeClassName="activeNav"
              className={`${styles.link} ${style}`}
              to={link.path}
            >
              {link.text}
              {index === 0 && place !== "footer" && (
                <MdArrowDropDown className={styles.iconDown} />
              )}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default NavLinks

NavLinks.propTypes = {
  place: PropTypes.string,
  isScrolled: PropTypes.bool
}

