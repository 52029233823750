import React from "react"
import styles from "./Dropdown.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import PropTypes from 'prop-types';

const getServices = graphql`
  query {
    allContentfulSluzba(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          serviceNameBold
          serviceName
          slug
        }
      }
    }
  }
`

const Dropdown = ({ isScrolled }) => {
  const links = useStaticQuery(getServices).allContentfulSluzba.edges

  return (
    <ul className={`${styles.dropdown} ${isScrolled ? styles.sticky : ""}`}>
      {links.map((service, i) => {
        return (
          <li key={i} className={styles.item}>
            <Link className={styles.link} to={`/sluzby/${service.node.slug}`}>
              {`${service.node.serviceName ? service.node.serviceName : ""} ${
                service.node.serviceNameBold
              }`}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Dropdown

Dropdown.propTypes = {
  isScrolled: PropTypes.bool,
}

