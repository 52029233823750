import React from "react"
import styles from "./ContactBox.module.scss"
import {
  FaRegEnvelopeOpen,
  FaUserAlt,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';

const ContactBox = ({ contact, icon, delay, footer }) => {
  let Icon, contactInfo;

  if (icon === "phone") {
    Icon = FaPhoneAlt
    contactInfo = "Telefon:"
  } else if (icon === "adress") {
    Icon = FaMapMarkerAlt
    contactInfo = "Adresa:"
  } else if (icon === "mail") {
    Icon = FaRegEnvelopeOpen
    contactInfo = "E-mail:"
  } else if (icon === "name") {
    Icon = FaUserAlt
    contactInfo = "Kontaktní osoba:"
  }

  return (
    <Fade bottom duration={700} delay={delay}>
      <div className={`${styles.contactBox} ${footer && styles.footer}`}>
        <Icon className={styles.icon} />
        <div>
          <div className={styles.description}>{contactInfo}</div>
          <div className={styles.contact}>{contact}</div>
        </div>
      </div>
    </Fade>
  )
}

export default ContactBox

ContactBox.propTypes = {
  contact: PropTypes.string,
  icon: PropTypes.string,
  delay: PropTypes.number,
  footer: PropTypes.bool,
}