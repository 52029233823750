export default [
  {
    path: "/sluzby",
    text: "Služby",
  },
  {
    path: "/reference",
    text: "Reference",
  },
  {
    path: "/kalendar",
    text: "Kalendář akcí",
  },
  {
    path: "/projekteu",
    text: "Projekty EU",
  },
  {
    path: "/kontakt",
    text: "Kontakt",
  },
]
