import React, { useState, useEffect, createContext } from "react"
import Navbar from "../nav/Navbar"
import Sidebar from "../nav/Sidebar"
import Footer from "./Footer"
import "../../styles/fonts.scss"
import "../../styles/global/global.scss"
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css"
import "focus-visible"
import { calcViewPortHeight } from "../../functions/functions"
import PropTypes from 'prop-types';

export const StatStateContext = createContext(null)

const Layout = ({ children, page }) => {
  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  useEffect(() => {
    calcViewPortHeight()
  }, [])

  return (
    <>
      <Navbar page={page} toggleNav={toggleNav} />
      <Sidebar isOpen={isOpen} toggleNav={toggleNav} />
      {children}
      <Footer />
    </>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
}
