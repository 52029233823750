import { useState, useEffect } from "react"
import { throttle } from "../functions/functions"

const useShowOnScroll = () => {
  const [scroll, setScroll] = useState(false)

  const scrollHandler = () => {
    if (window.scrollY > 50) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
    const throttleScroll = throttle(scrollHandler, 50)
    window.addEventListener("scroll", throttleScroll)
    return () => window.removeEventListener("scroll", throttleScroll)
  }, [])

  return scroll
}

export default useShowOnScroll
